import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_RouterLink, { to: { path: '/profile' } }, {
      default: _withCtx(() => [
        _createVNode(_component_Avatar, {
          src: _ctx.user.avatar
        }, null, 8, ["src"])
      ]),
      _: 1
    })
  ]))
}