<template>
    <div class="contaiter_main">
      <MainHeader />
      <slot>

      </slot>
      <MainFooter />
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import MainHeader from '@/components/MainHeader.vue'
  import MainFooter from '@/components/MainFooter.vue'
  
  
  export default defineComponent({
    name: 'MainLayout',
    components: {
      MainHeader,
      MainFooter,
    },
  });
  </script>
  
  <style scoped>
  .contaiter_main {
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
  }
  
  @media (max-width: 768px) {
    .contaiter_main {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  
  </style>