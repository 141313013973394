<template>
  <div class="main_header">
    <RouterLink :to="{ path: '/' }">
      <img class="brand" src="/logo.png" alt="">
    </RouterLink>
    <HeaderUserMenu />
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue"
import HeaderUserMenu from '@/components/HeaderUserMenu.vue'

export default defineComponent({
  name: 'MainHeader',

  data() {
    return {}
  },
  components: {
    HeaderUserMenu,
  },
  methods: {},

})
</script>


<style scoped>
.main_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 30px;
}

.brand {
  height: 60px;
  width: auto;
}
</style>