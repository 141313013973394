<template>
  <div class="rating">
    <v-table height="300px" theme="dark">
      <thead>
        <tr>
          <th class="text-left">Челлендж</th>
          <th class="text-left">Баллы</th>
          <th class="text-left">Описание</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, place) in ratingList" :key="`rate_${place}`">
          <td> {{ item.challenge.name }} </td>
          
          <td> <span :style="{color:formatScore(item.challenge.score).color, 'font-weight': 'bold'}">
            {{formatScore(item.challenge.score).score}}
          </span>  </td>
          <td> {{ item.desc }} </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue"
import Avatar from '@/components/Avatar.vue'
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: 'RatingTable',

  data() {
    return {
    }
  },
  components: {
    Avatar,
  },
  created() {
    this.fetchRatingList()
  },
  computed: {
    ...mapGetters(['ratingList']),
  },
  methods: {
    formatScore(score: number) {
      if (score && score > 0)
        return { score: `+${score}`, color: 'var(--green-color)' }
      if (score && score < 0)
        return { score: score, color: 'var(--red-color)' }
      return { score: '-', color: 'var(--yellow-color)' }
    },
    ...mapActions(['fetchRatingList']),
    getPlaceColor(place: number) {
      if (place <= 3) {
        return { color: '#4dd04d' }
      }
      if (place <= 6) {
        return { color: '#fdfd74' }
      }
      return { color: '#ff6e6e' }
    },

  },
})
</script>


<style scoped>
.rating {
  margin-bottom: 60px;
}
</style>