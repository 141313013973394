import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from '../views/LoginPage.vue'
import Profile from '../views/Profile.vue'
import store from '@/store';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true },
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

router.beforeEach((to, from, next) => { 
  if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) {
          next();
          return;
      }      
      next(`/login/?next=${to.path}`);
  } else {
      next();
  }
});