<template>
    <div class="fixed-overlay">
        <div class="modalbox">

            <div class="header">
                <h4> {{ title }} </h4>
            </div>
            <div class="modalbox_wrap">
                <slot></slot>
            </div>
        </div>
    </div>
</template>


<script lang="ts">
export default {
    name: "SimpleForm",
    props: {
        showClose: {
            type: Boolean,
            default: true,
        },
        isBurger:
        {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        width: {
            type: String,
        },
        height: {
            type: String,
        }
    },
    data() {
        return {};
    },
    watch: {},
    components: {},
    computed: {
    },
    methods: {
    },
    updated() { },
};
</script>


<style scoped>
.modalbox {
    width: 700px;
    height: 400px;
    background-color: rgb(82, 82, 82);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    overflow: hidden;
    color: #ffffff;
}

.modalbox_wrap {
    color: #000;
    width: 100%;
    overflow: scroll;
    display: flex;
    color: black;
    flex-direction: column;
    padding: 32px;
}


.header {
    height: 60px;
    text-align: center;
    position: sticky;
    top: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}


.close {
    position: absolute;
    width: 50px;
    height: 50px;
    padding: 15px;
    top: 0px;
    right: 0px;
    padding: 16px;
    cursor: pointer;
    transition: 0.1s;
    z-index: 200;
}

.close:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .modalbox {
        width: 100%;
        height: 100%;
        position: fixed;
        margin: 0 auto;
        top: 0;
        left: 0;
        border-radius: 0;
    }
}

@media (max-width: 576px) {
    .modalbox_wrap {
        padding: 15px;
    }

    .modalbox {
        width: 100%;
        
        position: fixed;
        margin: 0 auto;
        top: 0;
        left: 0;
        border-radius: 0;
    }

}
</style>
