<template>
  <form class="input_form" v-on:submit.prevent="AddChallenge">
    <div v-if="userMe.status && notPlaying " class="not_playing_message">
      Ваш статус "Не в игре"
      Обратитесь к администратору
    </div>
    
    <v-row dense>
      <v-col class="c-10" cols="12" md="8">
        <v-card theme="dark" class="mx-auto pa-6" elevation="8" rounded="lg">
          <v-container fluid>
            <v-select :disabled="notPlaying" required v-model="challengeId" :items="challengeList" item-title="name" item-value="id"
              label="Выбранный челлендж">
            </v-select>
            <v-textarea :disabled="notPlaying" label="Описание" variant="filled" v-model="challengeDesc" auto-grow></v-textarea>
            <v-btn :disabled="notPlaying" class="mb-8" color="blue" size="large" type="submit" variant="tonal" block>
              Отправить
            </v-btn>
          </v-container>
        </v-card>

      </v-col>
      <v-col class="c-10" cols="12" md="4">

        <div class="big_score">
          <span class="big_score_label">
            Влияние на рейтинг
          </span>
          <span :style="{ color: affectScore.color }" class="big_score_num">
            {{ affectScore.score }}
          </span>
        </div>

      </v-col>
    </v-row>


  </form>
</template>


<script lang="ts">
import axios from 'axios'
import { defineComponent } from "vue"
import { mapGetters, mapActions } from "vuex";

import { Challenge } from '@/typing/main'


export default defineComponent({
  name: 'AddChallenge',
  data() {
    return {
      challengeList: [] as Challenge[],
      challengeId: null,
      challengeDesc: '',
    }
  },
  components: {
  },
  created() {
    this.fetchChallengeList();
    this.fetchRatingList();
  },
  computed: {
    notPlaying() {
      return this.userMe.status !== 'playing';
    },
    affectScore() {
      if (this.challengeId) {
        const found = this.challengeList.find((element) => element.id == this.challengeId);
        if (found) {
          if (found.score > 0)
            return { score: `+${found.score}`, color: 'var(--green-color)' }
          if (found.score < 0)
            return { score: found.score, color: 'var(--red-color)' }
        }
      }
      return { score: '-', color: 'var(--yellow-color)' }
    },
    ...mapGetters(['userMe', 'ratingList']),
  },
  methods: {
    ...mapActions(['fetchUserMe', 'fetchRatingList']),
    AddChallenge() {
      const body = { challenge: this.challengeId, desc: this.challengeDesc };
      axios.post('/api/tournament/rating_create/', body)
        .then(() => {
          this.challengeId = null;
          this.challengeDesc = '';
          this.fetchRatingList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchChallengeList() {
      axios.get('/api/tournament/challenge/')
        .then((response) => {
          this.challengeList = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }

  },

})
</script>



<style scoped>
.challenge_selector {
  min-width: 300px;
  max-width: 100%;
}

.input_form {
  position: relative;
  display: flex;
  border-radius: 10px;
  gap: 30px;
  align-items: flex-start;
  margin-bottom: 30px;
  flex-wrap: wrap;
  width: 100%;
  background-color: #1f2007;
}

.not_playing_message {
  width: 100%;
  height: 100%;
  position:absolute;
  top:0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--yellow-color);
  z-index: 1;
}

.big_score {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--yellow-color);
  justify-content: center;
}

.big_score_num {
  color: var(--yellow-color);
  font-size: 50px;
}
</style>