<template>
  <div>
    <RouterLink :to="{ path: '/profile' }">
      <Avatar :src="user.avatar" />
    </RouterLink>
  </div>
</template>
  
  
<script lang="ts">
import axios from 'axios'
import { defineComponent } from "vue"
import Avatar from '@/components/Avatar.vue'
import { User } from '@/typing/main'



import { mapGetters, mapActions } from "vuex";
export default defineComponent({
  name: 'HeaderUserMenu',
  data() {
    return {
      user: {} as User
    }
  },
  components: {
    Avatar,
  },
  computed: {
    ...mapGetters(['userMe', 'isLoggedIn'])
  },
  beforeCreate() {
    if (this.isLoggedIn) {
      this.fetchUserMe();
    }
  },
  methods: {
    ...mapActions(['fetchUserMe']),
    fetchMe() {
      axios.get(`/api/auth/user/me/`)
        .then((response) => {
          this.user = response.data
        })
        .catch((err) => {
          console.log(err);
          
        });
    },
  },

})
</script>
  
  
<style scoped></style>