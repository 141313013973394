<template>
  <div class="d-flex align-center justify-space-around">
    <v-avatar v-if="src">
      <v-img alt="streamer" :src="src">
      </v-img>
    </v-avatar>

    <v-avatar v-else color="info">
      <v-icon icon="mdi-account-circle"></v-icon>
    </v-avatar>

  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue"


export default defineComponent({
  name: 'Avatar',
  props: {
    src: String || null || undefined,
  },
  data() {
    return {

    }
  },
  components: {
  },
  methods: {},

})
</script>


<style scoped></style>