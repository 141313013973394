<template>
  <div class="donats">
    <v-row dense>
      <v-col class="c-10" v-for="(donate, i) in donates" :key="i" cols="12" md="4">
          <a class="card_link" target="_blank" :href="donateLink"> 
            <div class="card">
              <div class="card__title">
                <div class="card__logo">
                  <svg width="100%" height="100%" viewBox="0 0 469.33333 469.33333" xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="m469.332031 234.667969c0 129.601562-105.0625 234.664062-234.664062 234.664062-129.605469 0-234.667969-105.0625-234.667969-234.664062 0-129.605469 105.0625-234.667969 234.667969-234.667969 129.601562 0 234.664062 105.0625 234.664062 234.667969zm0 0"
                    fill="#d2a10f"></path>
                    <g fill="#fafafa">
                      <path
                      d="m197.332031 341.332031c-8.832031 0-16-7.167969-16-16v-181.332031c0-8.832031 7.167969-16 16-16h80c35.285157 0 64 28.714844 64 64s-28.714843 64-64 64h-64v69.332031c0 8.832031-7.167969 16-16 16zm16-117.332031h64c17.644531 0 32-14.355469 32-32s-14.355469-32-32-32h-64zm0 0">
                    </path>
                    <path
                    d="m261.332031 309.332031h-117.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h117.332031c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0">
                  </path>
                </g>
              </svg>
            </div>
            {{ donate.score }}
          </div>
          <div class="card__subtitle">
            {{ donate.name }}
            
          </div>
        </div>
      </a>
      </v-col>
    </v-row>
  </div>
</template>


<script lang="ts">
import axios from 'axios'
import { defineComponent } from "vue"
import { Donate } from '@/typing/main'



export default defineComponent({
  name: 'DonatTable',

  data() {
    return {
      donateLink: 'https://www.donationalerts.com/r/johnnyinthedark',
      donates: [] as Donate[],
    }
  },
  components: {
  },
  created() {
    this.fetchDonates()
  },
  methods: {
    fetchDonates() {
      axios.get('/api/tournament/donates/')
        .then((response) => {
          this.donates = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

  },

})
</script>



<style scoped>
.donats {
  margin-bottom: 60px;
}

.card {
  padding: 20px;
  position: relative;
  min-height: 120px;
  border-radius: 10px;
  background-color: #212121;

}

.card__title {
  display: flex;
  gap: 10px;
  color: var(--yellow-color);
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.card__subtitle {
  color: white;
  font-size: 16px;
}

.card__logo {
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
}

.card {
  border: 2px solid transparent;
  transition: 0.5s ease all;
}

.card:hover {
  border: 2px solid var(--yellow-color);
}

</style>