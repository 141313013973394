<template>
  <div class="section_header">
    <div class="logo">
      <slot name="logo"></slot>
    </div>
    <div>
      {{ title }}
    </div>
    <div class="action">
      <slot name="action">  </slot>
    </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from "vue"


export default defineComponent({
  name: 'SectionHeader',
  props: {
    title: String,
  }
})
</script>


<style scoped>
.section_header {
  position: relative;
  margin-bottom: 20px;
  background-color: #313172;
  background: linear-gradient(90deg, #313172, #313172 51%, transparent);
  color: white;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 20px;
  align-items: center;
}

@media (max-width: 768px) {
  .section_header {
    
    background: unset;
    background-color: #313172;
  }
}

.logo {
  height: 32px;
  color: var(--yellow-color);
}

.action {
  position: absolute;
  right: 0;
  top: 12;
}
</style>