<template>
    <div class="text-center pa-4">
      <v-dialog
        theme="dark"
        v-model="dialog"
        max-width="400"
        persistent
      >
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn v-bind="activatorProps" color="error" class="mb-8" size="large" variant="tonal" block>
            {{ buttonText }}
            </v-btn>
        </template>
  
        <v-card
          :text="text"
          :title="title"
        >
          <template v-slot:actions>
            <v-spacer></v-spacer>
  
            <v-btn @click="dialog = false">
              Нет
            </v-btn>
  
            <v-btn @click="dialog = false;$emit('accept')">
              Да
            </v-btn>
          </template>
        </v-card>
      </v-dialog>
    </div>
  </template>



<script lang="ts">
export default {
    name: "ConfirmDialog",
    props: {
        buttonText: {
            type: String
        },
        text: {
            type: String
        },
        title: {
            type: String
        }
    },
    data() {
        return {
            dialog: false
        };
    },
    watch: {},
    components: {},
    computed: {
    },
    methods: {
    },
    updated() { },
};
</script>


<style scoped>

</style>
