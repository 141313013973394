<template>
  <MainLayout>


    <SectionHeader title="Взять челлендж">
      <template #logo>

        <svg fill="currentColor" height="100%" width="100%" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 297.5 297.5" xml:space="preserve">
          <path d="M262.502,99.5c0,8.284-6.716,15-15,15h-49c-2.949,0-5.834-0.869-8.291-2.5l-31.525-20.911L134.377,123.5h30.938
        c2.329,0,4.625,0.542,6.708,1.584l57.164,28.582c7.41,3.704,10.413,12.715,6.708,20.124c-3.705,7.408-12.715,10.416-20.124,6.708
        L161.774,153.5h-31.526l17.146,17.32c3.789,3.827,5.235,9.393,3.789,14.58c-1.446,5.188-5.563,9.203-10.785,10.519L83.666,210.21
        c-1.229,0.31-2.462,0.458-3.675,0.458c-6.711,0-12.821-4.536-14.535-11.34c-2.023-8.033,2.849-16.186,10.882-18.21l31.245-7.871
        l-23.95-24.194c-1.242-1.255-2.22-2.694-2.937-4.239c-5.04-7.699-5.034-18.028,0.806-25.814l35.514-47.351l-17.514-5.838
        L54.745,80.73c-7.86,2.617-16.354-1.627-18.974-9.487c-2.619-7.859,1.628-16.354,9.487-18.974l49.5-16.5
        c3.078-1.025,6.408-1.025,9.486,0l49.5,16.5c7.288,2.756,49.279,32.23,49.279,32.23h44.478
        C255.786,84.5,262.502,91.216,262.502,99.5z M180.252,49.5c13.669,0,24.75-11.081,24.75-24.75S193.921,0,180.252,0
        s-24.75,11.081-24.75,24.75S166.583,49.5,180.252,49.5z M179.206,182.031c-3.643,1.091-6.037,4.587-6.037,8.39v80.788
        c0,5.132-3.828,9.292-8.96,9.292h-30.955c-3.802,0-7.298,2.395-8.389,6.037c-1.647,5.497,2.522,10.963,7.886,10.963h41.25h-0.166
        c9.113,0,16.334-7.387,16.334-16.5v-0.5v-90.583C190.169,184.552,184.703,180.384,179.206,182.031z" />
        </svg>
      </template>
    </SectionHeader>
    <AddChallenge :userMe="userMe" />
    <RatingTable/>

    <SectionHeader title="Мой профиль">
      <template #logo>
        <svg width="100%" height="100%" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>

          </defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Dribbble-Light-Preview" transform="translate(-180.000000, -2159.000000)" fill="currentColor">
              <g id="icons" transform="translate(56.000000, 160.000000)">
                <path
                  d="M134,2008.99998 C131.783496,2008.99998 129.980955,2007.20598 129.980955,2004.99998 C129.980955,2002.79398 131.783496,2000.99998 134,2000.99998 C136.216504,2000.99998 138.019045,2002.79398 138.019045,2004.99998 C138.019045,2007.20598 136.216504,2008.99998 134,2008.99998 M137.775893,2009.67298 C139.370449,2008.39598 140.299854,2006.33098 139.958235,2004.06998 C139.561354,2001.44698 137.368965,1999.34798 134.722423,1999.04198 C131.070116,1998.61898 127.971432,2001.44898 127.971432,2004.99998 C127.971432,2006.88998 128.851603,2008.57398 130.224107,2009.67298 C126.852128,2010.93398 124.390463,2013.89498 124.004634,2017.89098 C123.948368,2018.48198 124.411563,2018.99998 125.008391,2018.99998 C125.519814,2018.99998 125.955881,2018.61598 126.001095,2018.10898 C126.404004,2013.64598 129.837274,2010.99998 134,2010.99998 C138.162726,2010.99998 141.595996,2013.64598 141.998905,2018.10898 C142.044119,2018.61598 142.480186,2018.99998 142.991609,2018.99998 C143.588437,2018.99998 144.051632,2018.48198 143.995366,2017.89098 C143.609537,2013.89498 141.147872,2010.93398 137.775893,2009.67298"
                  id="profile-[#1341]">

                </path>
              </g>
            </g>
          </g>
        </svg>
      </template>
    </SectionHeader>


    <v-row dense class="mb-4">
      <v-col class="c-10" cols="12" md="12">
        <v-card theme="dark" class="pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
          <div class="avatar">
            <Avatar :src="userMe.avatar" />
          </div>
          <v-text-field v-model="userMe.name" bg-color="transparent" disabled label="Имя"
            variant="underlined"></v-text-field>
          <v-text-field v-model="userMe.username" bg-color="transparent" disabled label="Логин"
            variant="underlined"></v-text-field>
          <ConfirmDialog 
            buttonText="Выйти из профиля" 
            text="Вы уверены что хотите выйти из системы?"
            title="Подвтердите действие"
            @accept="fetchLogout"/>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="userMe.is_superuser">
      <SectionHeader title="Сбросить челенджи">
        <template #logo>

          <svg width="100%" height="100%" viewBox="0 0 1024 1024" fill="currentColor" class="icon" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M32 241.6c-11.2 0-20-8.8-20-20s8.8-20 20-20l940 1.6c11.2 0 20 8.8 20 20s-8.8 20-20 20L32 241.6zM186.4 282.4c0-11.2 8.8-20 20-20s20 8.8 20 20v688.8l585.6-6.4V289.6c0-11.2 8.8-20 20-20s20 8.8 20 20v716.8l-666.4 7.2V282.4z"
              fill="" />
            <path
              d="M682.4 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM367.2 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM524.8 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM655.2 213.6v-48.8c0-17.6-14.4-32-32-32H418.4c-18.4 0-32 14.4-32 32.8V208h-40v-42.4c0-40 32.8-72.8 72.8-72.8H624c40 0 72.8 32.8 72.8 72.8v48.8h-41.6z"
              fill="" />
          </svg>
        </template>
      </SectionHeader>
      <ConfirmDialog 
      buttonText="Очистить весь рейтинг" 
      text="Вы уверены что хотите удалить всю таблицу рейтинга?"
      title="Подвтердите действие"
      @accept="deleteRating"/>
    </div>

  </MainLayout>
</template>

<script lang="ts">
import { mapGetters, mapActions } from "vuex";
import { defineComponent } from 'vue';
import SectionHeader from '@/components/SectionHeader.vue'
import MainLayout from '@/layouts/MainLayout.vue';
import AddChallenge from '@/components/AddChallenge.vue'
import Avatar from '@/components/Avatar.vue'
import ConfirmDialog from '@/components/uikit/ConfirmDialog.vue'
import RatingTable from '@/components/RatingTable.vue'


export default defineComponent({
  name: 'Profile',
  components: {
    SectionHeader,
    MainLayout,
    AddChallenge,
    Avatar,
    ConfirmDialog,
    RatingTable,
  },
  data() {
        return {
        };
    },
  created() {
    this.fetchUserMe()
  },
  computed: {
    ...mapGetters(['userMe']),
  },
  methods: {
    ...mapActions(["fetchLogout", "fetchUserMe", "deleteRating"]),
  }
});
</script>

<style scoped>
.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;
}

.user-info>span {
  font-size: 20px;
  color: white;
}

.avatar {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
</style>