import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center justify-space-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.src)
      ? (_openBlock(), _createBlock(_component_v_avatar, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_img, {
              alt: "streamer",
              src: _ctx.src
            }, null, 8, ["src"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_v_avatar, {
          key: 1,
          color: "info"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { icon: "mdi-account-circle" })
          ]),
          _: 1
        }))
  ]))
}