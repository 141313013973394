<template>
  <div>
    
  </div>

</template>


<script lang="ts">
import { defineComponent } from "vue"


export default defineComponent({
  name: 'MainFooter',

  data() {
    return {  }
  },
  components: {
  },
  methods: { },

})
</script>


<style scoped>
</style>