import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center pa-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_dialog, {
      theme: "dark",
      modelValue: $data.dialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.dialog) = $event)),
      "max-width": "400",
      persistent: ""
    }, {
      activator: _withCtx(({ props: activatorProps }) => [
        _createVNode(_component_v_btn, _mergeProps(activatorProps, {
          color: "error",
          class: "mb-8",
          size: "large",
          variant: "tonal",
          block: ""
        }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.buttonText), 1)
          ]),
          _: 2
        }, 1040)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          text: $props.text,
          title: $props.title
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_btn, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.dialog = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Нет ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_btn, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => {$data.dialog = false;_ctx.$emit('accept')})
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Да ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["text", "title"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}