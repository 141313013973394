import router from '@/router';
import axios from 'axios';
import { createStore } from 'vuex'


export default createStore({
  state: {
    jwt: localStorage.getItem('jwt') || '',
    errorMsg: '',
    loggedIn: false,
    user: {},
    ratingList: [],
  },
  getters: {
    isLoggedIn: (state) => Boolean(localStorage.getItem('loggedIn')) || false,
    jwt: (state) => state.jwt,
    errorMsg: (state) => state.errorMsg,
    userMe: (state) => state.user,
    ratingList: (state) => state.ratingList,
    Authorization(state) {
      let jwt = state.jwt;
      return (jwt) ? `Token ${jwt}` : '';
    },
  },
  mutations: {
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    setToken(state, jwt) {
      state.jwt = String(jwt);
      localStorage.setItem('jwt', jwt);
      state.errorMsg = '';
    },
    removeToken(state) {
      localStorage.setItem('jwt', '');
      state.jwt = '';
    },
    setIsLoggedIn(state, value) {
      localStorage.setItem('loggedIn', value);
    },
    setUser(state, value) {
      state.user = value;
    },
    setRatingList(state, data) {
      state.ratingList = data;
    }
  },
  actions: {
    authCheck(ctx) {
      axios.get(`/api/auth/check/`)
        .then((response) => {
          ctx.commit('setIsLoggedIn', response.data.status);
        })
        .catch((err) => {
          ctx.commit('setIsLoggedIn', false);
        });
    },
    fetchLogin(ctx, data) {
      axios.post(`/api/auth/token/`, data, {})
        .then((response) => {
          ctx.commit('setToken', response.data.token);
          ctx.commit('setIsLoggedIn', true);
          router.push('/profile');
        })
        .catch((err) => {
          ctx.commit('setErrorMsg', 'Неверный логин или пароль');
        });
    },
    fetchUserMe(ctx) {
      axios.get(`/api/auth/user/me/`)
        .then((response) => {
          ctx.commit('setUser', response.data);
        })
    },

    fetchLogout(ctx) {
      ctx.commit('removeToken');
      ctx.commit('setIsLoggedIn', false);
      router.push('/login/');
    },
    
    fetchRatingList(ctx) {
      axios.get(`/api/tournament/rating_list/`)
        .then((response) => {
          ctx.commit('setRatingList', response.data);
        })
        .catch((err) => {});
    },

    deleteRating(ctx) {
      axios.post(`/api/tournament/rating_delete/`)
        .then((response) => {
        })
        .catch((err) => {
        });
    },

  },
  modules: {}
})



